<template>
    <CustomMarker :options="{ position }">
        <UButton :color="color" :variant="variant" :size="size" square @click="() => !!popover ? toggle() : null">
            <UIcon 
                :name="type === 'my-location' ? icons.gps :icons[type] || icons.map"
            />
        </UButton>
    </CustomMarker>
    <InfoWindow 
        v-if="!!popover && isOpen"
        v-model="isOpen"
        :options="{ 
            position,
            headerContent: popover.label,
        }" 
    >
        <div class="pb-3 pt-4">
            
            <p class="text-xs font-bold text-gray-500 mb-3">{{ popover.type }}</p>
            <div class="flex flex-row flex-wrap gap-1 mb-2">
                <UBadge 
                v-for="tag in popover.tags" 
                variant="subtle" 
                color="primary" 
                size="xs"
                class="inline"
                >
                <span>{{tag}}</span>
            </UBadge>
            </div>
            
            <UButton
                v-if="!!popover.url"
                icon="i-heroicons-arrow-up-right-16-solid"
                color="primary"
                variant="soft"
                label="Bezoek website"
                aria-label="Naar regioloket"
                size="xs"
                :to="popover.url"
                target="_blank"
            />
        </div>
    </InfoWindow>
</template>
    
<script setup lang="ts">
    import { InfoWindow, CustomMarker } from 'vue3-google-map'
    import type { Marker, PopOverProps } from '~~/types/map';    
    import type { ButtonColor, ButtonVariant, ButtonSize } from '#ui/types'
    const props = withDefaults(
        defineProps<{
            popover: null | PopOverProps
            position: {
                lat: number
                lng: number
            }
            type: Marker['type']
            variant?: ButtonVariant
            color?: ButtonColor
            size?: ButtonSize
        }>(), {
            color: 'secondary',
            variant: 'soft',
            size: 'md'
        }
    )

    const { icons } = useConstants()
    
    const isOpen = ref(false)
    const toggle = () => {
        isOpen.value = !isOpen.value
    }
</script>
<style lang="postcss">
    .gm-style-iw-ch {
      @apply font-bold text-primary-600
    }
    .gm-style-iw-chr button {
      scale: 0.85
    }
</style>