<template>
  <ClientOnly>
    <template #fallback>
      <div 
        class="relative rounded-xl overflow-hidden w-full min-h-px"
        :style="`height: ${height};`"
      >
      <div
        class="absolute inset-0 z-10 grid bg-gray-100 w-full overflow-hidden place-items-center justify-center items-center animate-pulse"
      >
        <div
          class="absolute z-10 h-10 w-10 bg-white/70 rounded-full grid place-items-center"
        >
          <UIcon
            name="i-heroicons-arrow-path-20-solid"
            class="text-xl text-secondary-500 mx-0 my-0 animate-spin duration-800"
          />
        </div>
      </div>
    </div>
    </template>
    
   <GoogleMap
      ref="gmap"
      :api-key="apiKey"
      :mapId="mapId"
      class="w-full focus:ring-0 outline-0 rounded-xl overflow-hidden gmap bg-gray-100 dark:bg-gray-800"
      :style="`height: ${height}px; min-height: ${height}px;`"
      :center="center"
      :zoom="zoomForRadius || 15"
      map-type-id="roadmap"
      :zoom-control="zoomControl"
      :map-type-control="mapTypeControl"
      :scale-control="scaleControl"
      :street-view-control="streetViewControl"
      :rotate-control="rotateControl"
      :fullscreen-control="fullscreenControl"
      :disable-default-ui="disableDefaultUi"
      :keyboard-shortcuts="keyboardShortcuts"
    >
    <MarkerCluster 
      :options="{
        algorithm: clusterAlgorithm,
        renderer: clusterRenderer
      }"
    >
      <MapMarker
        v-for="marker in markers"
        :position="{lat: marker.lat, lng: marker.lng}"
        :popover="marker.popover || null"
        :type="marker.type"
        :color="marker.color"
        :variant="marker.variant"
        :size="marker.size"
      />
    </MarkerCluster>
    </GoogleMap>
  </ClientOnly>
</template>

<script setup lang="ts">
import { _emerald } from '#tailwind-config/theme/colors'
import { GoogleMap, MarkerCluster } from 'vue3-google-map'


const { mapId, apiKey } = useRuntimeConfig().public.google.maps

const defaultCenter = {
  lat: 52.2260351,
  lng: 5.3105003
}

// TODO get OWN geo key for onderwij sin (needs to verify credit card)
import type { Marker } from '~~/types/map';

const props = withDefaults(defineProps<{
  height?: number
  zoom?: number
  markers?: Marker[]
  center?: {
    lat: number
    lng: number
  }
  withCurrentLocation?: boolean
  zoomControl?: boolean
  mapTypeControl?: boolean
  scaleControl?: boolean
  streetViewControl?: boolean
  rotateControl?: boolean
  fullscreenControl?: boolean
  disableDefaultUi?: boolean
  keyboardShortcuts?: boolean
}>(), {
  markers: () => [] as Marker[],
  zoom: 15,
  height: 400,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUi: false,
  keyboardShortcuts: false,
});

// const search = useNavigatorStore()

const { isGeoSearch, location, searchRadiusAsInteger } = useGeolocation()
const center = computed(() => {
  if (!!props.withCurrentLocation && isGeoSearch.value) return {
    lat: location.value.lat as number,
    lng: location.value.lng as number
  }
  return props.center || defaultCenter
})

// const radius = computed(() => search.radiusAsInteger)

const zoomForRadius = computed(() => {
  if (!searchRadiusAsInteger.value || !isGeoSearch.value) return props.zoom
  const r = 11 - (searchRadiusAsInteger.value / 12)
  return r < 6 ? 6 : r
})

const markers: Ref<Marker[]> = computed(() => {
  if (props.withCurrentLocation && location.value.lat && location.value.lng) return [
    {
      lat: location.value.lat, lng: location.value.lng, type: 'my-location', id: 'my-location'
    },
    ...props.markers.filter(m => m.lat && m.lng)
  ]
  return props.markers.filter(m => m.lat && m.lng)
})


const { gmap, clusterRenderer, clusterAlgorithm, setCurrentLocationRadius, setCurrentLocationCircleCenter, hasCurrentLocationCircle, removeLocationRadius } = useMap()


const setBounds = () => {
  if (gmap.value?.ready && !!markers.value.length && markers.value.length > 1) {
    setTimeout(() => {
      if (gmap.value?.api) {
        const bounds = new gmap.value.api.LatLngBounds()
        markers.value.forEach((marker: Marker) => {
          bounds.extend({
              lat: marker.lat,
              lng: marker.lng,
          });
        })
        gmap.value.map.fitBounds(bounds, 10)
      }
    }, 200)
  }
}

watch(() => gmap.value?.ready, (newVal: boolean) => {
    if (newVal && markers.value.length > 1) {
      setBounds()
    }
    // Check if map contains "my-location"
    if (newVal && searchRadiusAsInteger.value && center.value) {
      setCurrentLocationRadius(searchRadiusAsInteger.value, center.value)
    }
})

watch(markers, (newVal) => setBounds())

watch([
  () => searchRadiusAsInteger.value,
], (newVal) => {
  if (props.withCurrentLocation) {
    if (searchRadiusAsInteger.value && center.value) {
      setCurrentLocationRadius(searchRadiusAsInteger.value, center.value)
    } else {
      setCurrentLocationRadius(0, center.value)
    }
  }
})

watch([
  () => center.value?.lat,
  () => center.value?.lng,
], (newVal) => {
  if (props.withCurrentLocation) {
    if (center.value && hasCurrentLocationCircle.value) {
      setCurrentLocationCircleCenter(center.value)
    } else if (center.value) {
      setCurrentLocationRadius(searchRadiusAsInteger.value, center.value)
    }
  }
})

watch(isGeoSearch, (newVal) => {
  if (!newVal) removeLocationRadius()
})

</script>


<style lang="postcss">
.gmap iframe + div {
  display: none
}
</style>