import { _secondary, _success } from '#tailwind-config/theme/colors'
import type { ClusterStats } from '~~/types/map'
import { GridAlgorithm } from '@googlemaps/markerclusterer'

type Center = { lat: number, lng: number }

export const useMap = () => {

  const gmap = ref()
  const circle = ref()

  const { isGeoSearch } = useGeolocation()

  const hasCurrentLocationCircle = computed(() => !!circle.value)

  const setCurrentLocationRadius = (val: number, center?: Center) => {
    if (!!isGeoSearch.value) {
      if (!circle.value) {
        circle.value = new gmap.value.api.Circle({
            strokeColor: _success[500],
            strokeOpacity: 0.4,
            strokeWeight: 2,
            fillColor: _success[500],
            fillOpacity: 0.25,
            map: gmap.value.map,
            center: new gmap.value.api.LatLng(center?.lat, center?.lng),
            radius: val * 1000, // in meters
            visible: true
        });
      } else {
        circle.value.setRadius(val * 1000)
      }
    }
  }

  const removeLocationRadius = () => {
    // Should use setMap(null) but that doesnt work...
    circle.value.setRadius(0)
    console.log(circle.value)
  }

  const setCurrentLocationCircleCenter = (center: Center) => {
    if (hasCurrentLocationCircle.value) circle.value.setCenter(new gmap.value.api.LatLng(center.lat, center.lng))
  }


  const clusterAlgorithm = new GridAlgorithm({
    gridSize: 24,
    maxZoom: 13,
  })

  const clusterRenderer = computed(() => ({
    render: ({ count, position }: { count: number, position: any, bounds: any}, stats: ClusterStats, map: any) => {
      return new gmap.value.api.marker.AdvancedMarkerElement({
        position,
        map,
        zIndex: count,
        content: createClusterIcon(count, stats)
      });
    }
  }))


  const createClusterIcon = (count: number, stats: ClusterStats) => {
    const { min, max } = stats.clusters.markers;
    // Calculate which shade to use based on count relative to min/max
    const normalizedValue = (count - min) / (max - min) || 1; // 0 to 1
    const shadeKeys = ["400", "500", "600", "700", "800", "900"] as const
    type Key = typeof shadeKeys[number]
    const shadeIndex = Math.floor(normalizedValue * (shadeKeys.length - 1));
    const color = _secondary[shadeKeys[shadeIndex] as Key];
    
    const size = 25;
    
    // Create container
    const container = document.createElement('div');
    Object.assign(container.style, {
      position: 'relative',
      width: size + 'px',
      height: size + 'px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    });

    // Create rings and main circle
    const elements = [
      // Outer ring (30% opacity)
      {
        size: (size + (count * 0.2)) * 1.6,
        opacity: 0.25,
        zIndex: 1
      },
      // Middle ring (60% opacity)
      {
        size: (size + (count * 0.2)) * 1.3,
        opacity: 0.5,
        zIndex: 2
      },
      // Main circle
      {
        size: (size + (count * 0.2)),
        opacity: 0.8,
        zIndex: 3
      }
    ];

    elements.forEach(({ size: elementSize, opacity, zIndex }) => {
      const element = document.createElement('div');
      Object.assign(element.style, {
        position: 'absolute',
        width: elementSize + 'px',
        height: elementSize + 'px',
        borderRadius: '50%',
        backgroundColor: color,
        opacity: opacity,
        zIndex: zIndex,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      });
      container.appendChild(element);
    });

    // Add count text
    const text = document.createElement('div');
    Object.assign(text.style, {
      position: 'relative',
      zIndex: 4,
      color: '#ffffff',
      fontSize: '12px',
      fontWeight: 'bold',
      textShadow: '0 1px 2px rgba(0,0,0,0.2)'
    });
    text.textContent = count.toString();
    container.appendChild(text);

    return container;
  }
  
  return {
    gmap,
    setCurrentLocationRadius,
    setCurrentLocationCircleCenter,
    hasCurrentLocationCircle,
    clusterAlgorithm,
    clusterRenderer,
    removeLocationRadius
  }
}
